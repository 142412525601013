import React, { FC } from 'react'
import { ReferencesButton } from './components/ReferencesButton'
import { ReferencesList } from './components/ReferencesList'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'

export const References: FC = () => {
  const { eventBus } = useAppContext<AppContext>()

  return (
    <>
      <ReferencesList header="Готовые справки">
        <ReferencesButton
          title="Посмотреть справки"
          description="Выпущенные за последние 180 дней"
          onClick={() => {
            eventBus.referencesModal.open.emit({
              type: 'issued',
            })
          }}
        />
      </ReferencesList>
      <ReferencesList header="Кредиты">
        <ReferencesButton
          title="По счетам и иному имуществу"
          description="Для декларации, форма 5798-У"
          onClick={() => {
            eventBus.referencesModal.open.emit({
              type: 'accounts',
            })
          }}
        />
      </ReferencesList>
    </>
  )
}
