import { FC } from 'react'
import { useSettingsQuery } from '@/api/settings/settings'
import { ContentError } from '@/components/ContentError'
import { ReferencesModal } from '@/features/references-modal'
import { References } from '@/features/references/References'
import { ResponseType } from '@/models/ResponseType'

export const ReferencesPage: FC = () => {
  const { data: settingsData, isLoading } = useSettingsQuery()

  const isReferencesEnabled =
    settingsData?.type === ResponseType.ResolveWithData && settingsData.result.enableReferences

  if (!isReferencesEnabled && !isLoading) {
    return <ContentError />
  }

  return (
    <>
      <References />
      <ReferencesModal />
    </>
  )
}
