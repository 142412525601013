import styled from '@emotion/styled'
import { ButtonBase } from '@platform-ui/components'
import { DocsIcon } from '@platform-ui/icons'

export const Root = styled(ButtonBase)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  color: theme.color['text/main'],
  backgroundColor: theme.color['background/secondary'],
  borderRadius: '12px',
  width: '100%',
  padding: '12px',

  [`${theme.media.md}`]: {
    width: 'auto',
    padding: '20px',
    flexBasis: 'calc(50% - 8px)',
  },
}))

export const Icon = styled(DocsIcon)(({ theme }) => ({
  color: theme.color['icons/primary'],
  width: '32px',
  marginBottom: '16px',
}))

export const Title = styled.div(({ theme }) => ({
  ...theme.typography['headline/14'],
  fontWeight: 600,
  color: theme.color['text/main'],

  [`${theme.media.md}`]: {
    ...theme.typography['headline/16'],
  },
}))

export const Description = styled.div(({ theme }) => ({
  ...theme.typography['body/12'],
  fontWeight: 500,
  color: theme.color['text/secondary'],

  [`${theme.media.md}`]: {
    ...theme.typography['body/14'],
  },
}))
