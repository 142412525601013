import React from 'react'
import Head from '@/next/head'
import { AppPage } from '@/models/AppPage'
import DefaultPage from '@/layouts/DefaultPage'
import { customTitle } from '@/utils/customTitle'
import { ReferencesPage } from '@/containers/ReferencesPage'

const Page: AppPage = () => {
  return (
    <>
      <Head>
        <title>{customTitle('Справки')}</title>
      </Head>
      <DefaultPage>
        <ReferencesPage />
      </DefaultPage>
    </>
  )
}

export default Page
