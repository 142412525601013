import React, { useCallback, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import dynamic from '@/next//dynamic'
import { DynamicLoading } from '@/containers/DynamicLoading'
import { ErrorBoundary } from '@/containers/ErrorBoundary'

const ReferencesModalContent = dynamic(() => import('./containers/ReferencesModalContent'), {
  loading: (props) => {
    return <DynamicLoading {...props} />
  },
})

export const ReferencesModal = observer(() => {
  const { eventBus } = useAppContext<AppContext>()

  const dynamicActive = useRef(false)

  const [isOpen, setIsOpen] = useState(false)
  const [type, setType] = useState<string | null>(null)

  useEffect(() => {
    const openUnsubscribe = eventBus.referencesModal.open.on((props) => {
      setIsOpen(true)
      setType(props.type)
    })

    const closeUnsubscribe = eventBus.referencesModal.close.on(() => {
      setIsOpen(false)
      setType(null)
    })

    return () => {
      openUnsubscribe()
      closeUnsubscribe()
    }
  }, [eventBus])

  const handleClose = useCallback(() => {
    eventBus.referencesModal.close.emit()
  }, [eventBus])

  /**
   * Препятствует загрузке компонента до его востребования
   */
  if (isOpen) {
    dynamicActive.current = true
  }

  return (
    <ErrorBoundary onCatchError={handleClose}>
      {dynamicActive.current && (
        <ReferencesModalContent type={type} isOpen={isOpen} onClose={handleClose} />
      )}
    </ErrorBoundary>
  )
})
