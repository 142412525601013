import { ReactNode, FC } from 'react'
import { Container, ContainerContent, ContainerHeader } from './styled'

interface ReferencesListProps {
  header: string
  childer: ReactNode
}

export const ReferencesList: FC<ReferencesListProps> = (props) => {
  const { header, children } = props
  return (
    <Container>
      <ContainerHeader>{header}</ContainerHeader>
      <ContainerContent>{children}</ContainerContent>
    </Container>
  )
}
