import styled from '@emotion/styled'
import { Headline28 } from '@platform-ui/typography'

export const Container = styled.div(() => ({
  marginBottom: '40px',
}))

export const ContainerHeader = styled(Headline28)(() => ({
  marginBottom: '20px',
}))

export const ContainerContent = styled.div(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  flexDirection: 'column',
  flexBasis: '50%',

  [`${theme.media.md}`]: {
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
}))
