import { FC } from 'react'
import { Description, Icon, Root, Title } from './styled'
import { ButtonBaseProps } from '@platform-ui/components'

interface ReferencesButtonProps extends ButtonBaseProps {
  title: string
  description: string
}

export const ReferencesButton: FC<ReferencesButtonProps> = (props) => {
  const { title, description, ...restProps } = props

  return (
    <Root {...restProps}>
      <Icon size="s18" />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Root>
  )
}
